.projets {
    --border-height: 36px;
    --padding-x: 36px;
    --padding-y: 150px;
    --total-padding-x: calc(var(--padding-x) * 2);

    background-color: $c-bg2;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url("../assets/images/texture2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: calc(var(--padding-y) - 112px) var(--padding-x) var(--padding-y) var(--padding-x);
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: calc(100% + var(--padding-x));
        left: calc(-1 * var(--padding-x));
        height: var(--border-height);
        background-image: url('../assets/images/bordure.svg');
        background-repeat: repeat-x;
        animation: scribble infinite 0.4s;
    }

    &::before {
        top: calc(-1 * (var(--border-height)/2));
    }

    &::after {
        bottom: calc(-1 * (var(--border-height)/2));
    }

    &__title {
        padding-top: 112px;
        margin-bottom: 112px;
        position: relative;
        z-index: 9;
    }

    &__list {
        display: grid;
        justify-content: center;
        row-gap: 84px;
    }

    &__el {
        display: none;
        transition: transform 0.3s ease, opacity 0.5s ease;
    }

    &__el:first-child {
        display: block;
        transform: rotate(-10deg);
    }

    &__el:nth-child(3) {
        display: block;
        transform: rotate(2deg);
    }

    &__el:nth-child(5) {
        display: block;
        transform: rotate(10deg);
    }

    & img {
        width: 266px;
        vertical-align: middle;
        animation: smoke 5s ease-in infinite;

        @keyframes smoke {
            0% {
                filter: drop-shadow(0 0 10px rgba($c-btn, 0.5));
            }

            50% {
                filter: drop-shadow(0 0 30px rgba($c-btn, 1));
            }

            100% {
                filter: drop-shadow(0 0 10px rgba($c-btn, 0.5));
            }
        }
    }

    &__follow1 {
        transition: transform 0.1s ease;
    }

    &__follow2 {
        transition: transform 0.3s ease;
    }

    &__follow3 {
        transition: transform 0.2s ease;
    }

    &__presentation {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100%;
        min-width: 100%;
        opacity: 0;
        transition: opacity 0.2s ease;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 20;
        padding: 0 36px;

        & a {
            margin-top: 15vh;
            margin-bottom: 36px;

            @media(min-width: 600px) {
                width: 354px;
                margin-bottom: 47px;
            }

            @media(min-width: 1300px) {
                width: 472px;
                margin-bottom: 63px;
            }

            @media(min-width: 2200px) {
                width: 629px;
                margin-bottom: 84px;
            }
        }

        &.hide {
            display: none;
        }

        &.fade-in {
            opacity: 1;
        }

        &::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba($c-bg, 1), rgba($c-bg2, 1));
            background-size: cover;
            background-position: center;
            z-index: -1;
        }
    }

    &__back {
        display: block;
        text-align: center;
        color: $c-text;
        font-family: $font-title;
        transition: 0.2s ease;
        font-size: 20px;
        padding: 11px 63px 11px 47px;
        margin: 20px;
        position: relative;
        z-index: 9;
        position: absolute;
        top: 0;
        right: 0;

        &::after {
            content: '';
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 36px;
            width: 100%;
            height: 100%;
            background-image: url("../assets/images/cross.svg");
            background-repeat: no-repeat;
            background-position: center right;
            background-size: 15px;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $c-btn;
            z-index: -1;
            animation: scribble infinite 0.4s;
        }

        &:hover {
            @media (min-width: 1300px) {
                transform: scale(1.1);
            }
        }
    }

    &__btn {
        &.disabled {
            pointer-events: none;
        }
    }
}

@media(min-width: 600px) {
    .projets {
        --border-height: 47px;
        --padding-x: 84px;
        --padding-y: 199px;

        & img {
            width: 100%;
        }

        &__title {
            margin-bottom: 150px;
        }

        &__list {
            grid-template-columns: 1fr 1fr;
            row-gap: 47px;
            column-gap: 47px;
        }

        &__el:first-child {
            transform: translateX(50px) scale(0.9) rotate(-5deg);
        }

        &__el:nth-child(3) {
            transform: translateX(-50px) scale(0.8);
        }

        &__el:nth-child(5) {
            transform: translateY(50px) scale(0.9) rotate(15deg);
        }

        &__el:nth-child(2) {
            display: block;
            transform: translateX(50px) translateY(50px) scale(0.7);
        }

        &__el:nth-child(7) {
            display: block;
            transform: translateX(50px) translateY(-50px) scale(0.6);
        }
    }
}

@media(min-width: 1300px) {
    .projets {
        --border-height: 63px;
        --padding-x: 12vw;
        --padding-y: 354px;

        &__title {
            margin-bottom: 199px;
        }

        &__list {
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 84px;
            column-gap: 84px;
        }

        &__el {
            &:first-child {
                transform: translateX(20px) translatey(20px) rotate(10deg) scale(0.9);

                &:hover {
                    transform: translateX(20px) translatey(20px) rotate(0deg) scale(1);
                }
            }

            &:nth-child(2) {
                transform: translateX(-50px) translatey(200px) scale(0.7);

                &:hover {
                    transform: translateX(-50px) translatey(200px) scale(0.8);
                }
            }

            &:nth-child(3) {
                transform: translateX(-50px) translatey(20px) rotate(-5deg);

                &:hover {
                    transform: translateX(-50px) translatey(20px) rotate(0deg) scale(1.1);
                }
            }

            &:nth-child(4) {
                display: block;
                transform: translateX(50px) rotate(-5deg) scale(0.6);

                &:hover {
                    transform: translateX(50px) rotate(0deg) scale(0.7);
                }
            }

            &:nth-child(5) {
                transform: translateX(150px) translatey(200px) scale(0.95);

                &:hover {
                    transform: translateX(150px) translatey(200px) scale(1.05);
                }
            }

            &:nth-child(6) {
                display: block;
                transform: translateX(50px) translatey(50px) rotate(5deg) scale(0.5);

                &:hover {
                    transform: translateX(50px) translatey(50px) rotate(0deg) scale(0.6);
                }
            }

            &:nth-child(7) {
                transform: translateX(250px) translatey(-200px) scale(0.5);

                &:hover {
                    transform: translateX(250px) translatey(-200px) scale(0.6);
                }
            }
        }

        &__link {
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

@media(min-width: 2200px) {
    .projets {
        --border-height: 84px;
        --padding-x: 12vw;
        --padding-y: 472px;

        &__title {
            margin-bottom: 266px;
        }
    }
}