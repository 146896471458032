.hello {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(150px - 112px) 36px 150px 36px;
    position: relative;

    &__list {
        margin-bottom: 84px;
        max-width: 354px;
        z-index: 9;
    }

    &__el:nth-child(2) {
        margin-bottom: 36px;

        & p {
            font-size: 27px;
            line-height: 120%;
        }
    }

    &__container {
        transform: rotate(10deg);
    }

    &__me {
        width: 266px;
    }

    &__title {
        padding-top: 112px;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 10vh;
        background: linear-gradient(to bottom, rgba($c-btn, 0), rgba($c-btn, 0.08));
        z-index: -1;
    }

    &__follow {
        transition: transform 0.1s ease;
    }
}

@media(min-width: 600px) {
    .hello {
        padding: calc(199px - 112px) 84px 199px 84px;

        &__list {
            margin-bottom: 112px;
            max-width: 472px;
        }

        &__el:nth-child(2) {
            margin-bottom: 47px;

            & p {
            font-size: 36px;
            }
        }

        &__me {
            width: 354px;
        }
    }
}

@media(min-width: 1300px) {
    .hello {
        padding: calc(354px - 112px) 12vw 354px 12vw;
        flex-direction: row;
        justify-content: space-between;

        &__list {
            margin-bottom: 0;
        }

        &__me {
            width: 30vw;
            padding-top: 112px;
        }
    }
}

@media(min-width: 1900px) {
    .hello {
        &__me {
            width: 629px;
        }
    }
}

@media(min-width: 2200px) {
    .hello {
        padding: calc(472px - 112px) 12vw 472px 12vw;

        &__list {
            margin-bottom: 112px;
            max-width: 629px;
        }

        &__el:nth-child(2) {
            margin-bottom: 63px;

            & p {
            font-size: 47px;
            }
        }

        &__me {
            width: 35vw;
        }
    }
}