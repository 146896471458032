.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 600px) {
        padding-top: 99px;
    }

    &__title {
        font-size: 112px;
        margin-bottom: 27px;
        line-height: 100%;
        position: relative;
        z-index: 9;
    }

    &__title span {
        display: block;
    }
}

@media(min-width: 600px) {
    .home {
        &__title {
            font-size: 150px;
            margin-bottom: 36px;
        }
    }
}

@media(min-width: 1300px) {
    .home {
        &__title {
            font-size: 199px;
            margin-bottom: 47px;
        }

        &__nom {
            font-size: 27px;
        }
    }
}

@media(min-width: 2200px) {
    .home {
        &__title {
            font-size: 266px;
            margin-bottom: 63px;
        }

        &__nom {
            font-size: 36px;
        }
    }
}