.jeu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(150px - 112px) 36px 0 36px;
    position: relative;

    &__title {
        padding-top: 112px;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 10vh;
        background: linear-gradient(to top, rgba($c-btn, 0), rgba($c-btn, 0.08));
        z-index: -1;
    }

    &__list {
        max-width: 354px;
        z-index: 9;
    }

    &__img1 {
        margin: 84px 0;
    }

    &__img2 {
        display: none;
    }

    &__btn {
        display: block;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
        color: $c-text;
        font-family: $font-title;
        transition: 0.2s ease;
        font-size: 20px;
        padding: 11px 63px 11px 47px;
        position: relative;

        &:hover {
            @media (min-width: 1300px) {
                transform: scale(1.1);
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $c-btn;
            z-index: -1;
            animation: scribble infinite 0.4s;
        }

        &::after {
            content: '';
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 36px;
            width: 100%;
            height: 100%;
            background-image: url("../assets/images/play.svg");
            background-repeat: no-repeat;
            background-position: center right;
            background-size: 15px;
            z-index: 1;
        }
    }

    &__container {
        transform: rotate(-10deg);
    }

    &__follow {
        display: flex;
        justify-content: center;
        transition: transform 0.1s ease;
    }
}

@media(min-width: 600px) {
    .jeu {
        padding: calc(199px - 112px) 84px 0 84px;

        &__list {
            max-width: 472px;
        }

        &__btn {
            font-size: 27px;
            padding: 15px 84px 15px 63px;

            &::after {
                content: '';
                right: 47px;
                background-size: 20px;
            }
        }

        &__img1 {
            margin: 112px 0;
        }
    }
}

@media(min-width: 1300px) {
    .jeu {
        padding: calc(354px - 112px) 12vw 0 12vw;
        flex-direction: row-reverse;
        justify-content: space-between;

        &__img1 {
            display: none;
        }

        &__img2 {
            display: block;
        }

        &__btn {
            margin: 0;
            margin-top: 47px;
        }

        &__list {
            margin-bottom: 112px;
        }
    }
}

@media(min-width: 1900px) {
    .jeu {
        &__btn {
            margin-top: 63px;
        }
    }
}

@media(min-width: 2200px) {
    .jeu {
        padding: calc(472px - 112px) 12vw 0 12vw;

        &__list {
            max-width: 629px;
        }

        &__btn {
            margin-top: 84px;
            font-size: 36px;
            padding: 20px 112px 20px 84px;

            &::after {
                content: '';
                right: 63px;
                background-size: 27px;
            }
        }
    }
}