.textbox {
    &__list {
        background-color: $c-bg2;
        border-radius: 15px;
        position: relative;
        z-index: 9;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 6px solid $c-btn;
            border-radius: 15px;
            z-index: 1;
            animation: scribble infinite 0.4s;

            @media(min-width: 600px) {
                border-radius: 20px;
            }

            @media(min-width: 2200px) {
                border-radius: 27px;
            }
        }

        &::after {
            content: "";
            background-image: url('../assets/images/indicateur.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            height: fit-content;
            height: 11px;
            width: 20px;
            bottom: 15%;
            right: 10%;
            transform: translateX(50%) translateY(50%);
            z-index: 2;
            animation: continue 1s infinite;
            transition: transform opacity 0.2s ease;
            opacity: 1;

            @media(min-width: 600px) {
                height: 15px;
                width: 27px;
            }

            @media(min-width: 2200px) {
                height: 20px;
                width: 36px;
            }

            @keyframes continue {
                0% {
                    transform: translateX(50%) translateY(50%);
                    opacity: 1;
                }

                50% {
                    transform: translateX(50%) translateY(60%);
                    opacity: 0.5;
                }

                100% {
                    transform: translateX(50%) translateY(50%);
                    opacity: 1;
                }
            }
        }

        &.hide::after{
            content: none;
        }

        @media(min-width: 600px) {
            border-radius: 20px;
        }

        @media(min-width: 2200px) {
            border-radius: 27px;
        }
    }

    &__el {
        &.hide {
            display: none;
        }
    }

    &__text {
        position: relative;
        font-size: 20px;
        font-family: $font-text;
        line-height: 140%;
        color: $c-text;
        padding: 36px 20px;
        text-align: start;
        z-index: 3;
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.2s ease, transform 0.2s ease;

        &.animation {
            animation: appear 1s forwards;
        }

        & span {
            display: block;
        }

        @media(min-width: 600px) {
            padding: 47px 27px;
            font-size: 27px;
        }

        @media(min-width: 2200px) {
            padding: 63px 36px;
            font-size: 36px;
        }

        @keyframes appear {
            from {
                opacity: 0;
                transform: translateY(-20px);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    &__underel {
        display: none;

        &.visible {
            display: block;
        }
    }
}