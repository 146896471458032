.main--credits {
    padding: 87px 36px 36px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;

    @media (min-width: 600px) {
        padding-top: 99px;
    }
}

.credits {
    z-index: 10;

    & h2 {
        padding-top: 36px;
    }

    & h3 {
        font-size: 27px;
        line-height: 120%;
        margin-bottom: 20px;
    }

    & ul {
        margin-bottom: 36px;
    }

    @media (min-width: 600px) {
        & h2 {
            padding-top: 47px;
        }

        & h3 {
            font-size: 36px;
            margin-bottom: 27px;
        }
    
        & ul {
            margin-bottom: 47px;
        }
    }

    @media (min-width: 2200px) {
        & h2 {
            padding-top: 63px;
        }

        & h3 {
            font-size: 47px;
            margin-bottom: 36px;
        }
    
        & ul {
            margin-bottom: 63px;
        }
    }
}