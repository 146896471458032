@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@400..800&family=Londrina+Solid:wght@100;300;400;900&display=swap');

::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($c-btn, 0.7);
  cursor: pointer;

  &:hover {
    background-color: $c-btn;
  }
}

::-webkit-scrollbar-track {
  background-color: $c-bg2;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: $c-bg2;
  font-family: $font-text;
  font-size: $size-text;
  color: #ffffff;
  overflow-x: hidden;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/texture1.png");
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  &.loaded {
    overflow-y: auto;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body::before {
    background-image: url("../assets/images/texture1@2x.png");
  }
}

footer {
  position: relative;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $c-bg2;
  padding: 47px 0 15px 0;
  margin-top: 266px;

  & img {
    vertical-align: middle;
  }

  & small {
    margin-top: 36px;
  }

  & small a {
    text-decoration: underline;
  }
}

p {
  line-height: 140%;
  margin: 0;
}

h1 {
  font-family: $font-title;
  color: $c-title;
  margin: 0;
  animation: scribble infinite 0.4s;
}

h2 {
  font-size: 84px;
  font-family: $font-title;
  color: $c-title;
  margin: 0;
  margin-bottom: 36px;
  animation: scribble infinite 0.4s;
  line-height: 120%;
}

h3 {
  font-family: $font-title;
  color: $c-title;
  margin: 0;
  animation: scribble infinite 0.4s;
}

.filtre--titre {
  display: none;
}

small {
  font-size: 15px
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: $c-text;
  text-decoration: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.move {
  --rotatex: 0deg;
  --rotatey: 0deg;
  transform: rotateX(var(--rotatex)) rotateY(var(--rotatey));
  transition: transform 0.1s ease;
}

.canvas-lampe {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 5;
}

@media(min-width: 600px) {
  footer {
    margin-top: 354px;
  }

  h2 {
    font-size: 112px;
    margin-bottom: 47px;
  }
}

@media(min-width: 1300px) {
  footer {
    margin-top: 472px;

    & small a {
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media(min-width: 1900px) {
  h2 {
    font-size: 150px;
    margin-bottom: 63px;
  }
}

@media(min-width: 2200px) {
  footer {
    margin-top: 629px;
  }

  p {
    font-size: 27px;
  }

  h2 {
    font-size: 199px;
    margin-bottom: 84px;
  }
}

@keyframes scribble {
  0% {
    filter: url("#scribble--0");
  }

  25% {
    filter: url("#scribble--1");
  }

  50% {
    filter: url("#scribble--2");
  }

  75% {
    filter: url("#scribble--3");
  }

  100% {
    filter: url("#scribble--4");
  }
}