@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Eczar:wght@400..800&family=Londrina+Solid:wght@100;300;400;900&display=swap");
::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(113, 40, 159, 0.7);
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #71289F;
}

::-webkit-scrollbar-track {
  background-color: #09030D;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #09030D;
  font-family: "Eczar", serif;
  font-size: 20px;
  color: #ffffff;
  overflow-x: hidden;
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/texture1.png");
  background-size: cover;
  background-position: center;
  z-index: -1;
}
body.loaded {
  overflow-y: auto;
}
body.no-scroll {
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body::before {
    background-image: url("../assets/images/texture1@2x.png");
  }
}
footer {
  position: relative;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #09030D;
  padding: 47px 0 15px 0;
  margin-top: 266px;
}
footer img {
  vertical-align: middle;
}
footer small {
  margin-top: 36px;
}
footer small a {
  text-decoration: underline;
}

p {
  line-height: 140%;
  margin: 0;
}

h1 {
  font-family: "Londrina Solid", sans-serif;
  color: #7BEA44;
  margin: 0;
  animation: scribble infinite 0.4s;
}

h2 {
  font-size: 84px;
  font-family: "Londrina Solid", sans-serif;
  color: #7BEA44;
  margin: 0;
  margin-bottom: 36px;
  animation: scribble infinite 0.4s;
  line-height: 120%;
}

h3 {
  font-family: "Londrina Solid", sans-serif;
  color: #7BEA44;
  margin: 0;
  animation: scribble infinite 0.4s;
}

.filtre--titre {
  display: none;
}

small {
  font-size: 15px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #ffffff;
  text-decoration: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.move {
  --rotatex: 0deg;
  --rotatey: 0deg;
  transform: rotateX(var(--rotatex)) rotateY(var(--rotatey));
  transition: transform 0.1s ease;
}

.canvas-lampe {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 5;
}

@media (min-width: 600px) {
  footer {
    margin-top: 354px;
  }

  h2 {
    font-size: 112px;
    margin-bottom: 47px;
  }
}
@media (min-width: 1300px) {
  footer {
    margin-top: 472px;
  }
  footer small a {
    transition: opacity 0.2s ease;
  }
  footer small a:hover {
    opacity: 0.8;
  }
}
@media (min-width: 1900px) {
  h2 {
    font-size: 150px;
    margin-bottom: 63px;
  }
}
@media (min-width: 2200px) {
  footer {
    margin-top: 629px;
  }

  p {
    font-size: 27px;
  }

  h2 {
    font-size: 199px;
    margin-bottom: 84px;
  }
}
@keyframes scribble {
  0% {
    filter: url("#scribble--0");
  }
  25% {
    filter: url("#scribble--1");
  }
  50% {
    filter: url("#scribble--2");
  }
  75% {
    filter: url("#scribble--3");
  }
  100% {
    filter: url("#scribble--4");
  }
}
.menu {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  transform: translateY(0%);
  z-index: 15;
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Londrina Solid", sans-serif;
  padding: 20px;
  background: #1B0C21;
}
.menu__list {
  display: flex;
  flex-direction: row;
}
.menu__el {
  margin-right: 20px;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}
.menu__el--active {
  opacity: 1;
}
.menu__el:last-child {
  margin-right: 0;
}
.menu__logo img {
  max-height: 36px;
  width: fit-content;
}
.menu__btn {
  display: none;
}
.menu--hidden {
  transform: translateY(-100%);
}

@media (min-width: 600px) {
  .menu {
    font-size: 27px;
    justify-content: space-between;
  }
  .menu__el {
    margin-right: 36px;
  }
  .menu__btn {
    display: block;
    font-size: 20px;
    padding: 11px 63px 11px 47px;
    position: relative;
  }
  .menu__btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #71289F;
    z-index: -1;
    animation: scribble infinite 0.4s;
  }
  .menu__btn--play::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 36px;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/play.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 15px;
    z-index: 1;
  }
  .menu__btn--retry::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 36px;
    width: 100%;
    height: 100%;
    background-image: url("../assets/images/refresh.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20px;
    z-index: 1;
  }
  .menu__logo img {
    max-height: 47px;
  }
}
@media (min-width: 1300px) {
  .menu {
    padding: 20px 47px;
  }
  .menu__container {
    width: 159px;
    display: flex;
  }
  .menu__container--game {
    width: 219px;
  }
  .menu__btn {
    transition: transform 0.2s ease;
  }
  .menu__btn:hover {
    transform: scale(1.1);
  }
  .menu__logo {
    transition: transform 0.2s ease;
  }
  .menu__logo:hover {
    transform: scale(1.1);
  }
  .menu__el:hover {
    opacity: 0.8;
  }
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .home {
    padding-top: 99px;
  }
}
.home__title {
  font-size: 112px;
  margin-bottom: 27px;
  line-height: 100%;
  position: relative;
  z-index: 9;
}
.home__title span {
  display: block;
}

@media (min-width: 600px) {
  .home__title {
    font-size: 150px;
    margin-bottom: 36px;
  }
}
@media (min-width: 1300px) {
  .home__title {
    font-size: 199px;
    margin-bottom: 47px;
  }
  .home__nom {
    font-size: 27px;
  }
}
@media (min-width: 2200px) {
  .home__title {
    font-size: 266px;
    margin-bottom: 63px;
  }
  .home__nom {
    font-size: 36px;
  }
}
.hello {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 36px 150px 36px;
  position: relative;
}
.hello__list {
  margin-bottom: 84px;
  max-width: 354px;
  z-index: 9;
}
.hello__el:nth-child(2) {
  margin-bottom: 36px;
}
.hello__el:nth-child(2) p {
  font-size: 27px;
  line-height: 120%;
}
.hello__container {
  transform: rotate(10deg);
}
.hello__me {
  width: 266px;
}
.hello__title {
  padding-top: 112px;
}
.hello::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background: linear-gradient(to bottom, rgba(113, 40, 159, 0), rgba(113, 40, 159, 0.08));
  z-index: -1;
}
.hello__follow {
  transition: transform 0.1s ease;
}

@media (min-width: 600px) {
  .hello {
    padding: 87px 84px 199px 84px;
  }
  .hello__list {
    margin-bottom: 112px;
    max-width: 472px;
  }
  .hello__el:nth-child(2) {
    margin-bottom: 47px;
  }
  .hello__el:nth-child(2) p {
    font-size: 36px;
  }
  .hello__me {
    width: 354px;
  }
}
@media (min-width: 1300px) {
  .hello {
    padding: 242px 12vw 354px 12vw;
    flex-direction: row;
    justify-content: space-between;
  }
  .hello__list {
    margin-bottom: 0;
  }
  .hello__me {
    width: 30vw;
    padding-top: 112px;
  }
}
@media (min-width: 1900px) {
  .hello__me {
    width: 629px;
  }
}
@media (min-width: 2200px) {
  .hello {
    padding: 360px 12vw 472px 12vw;
  }
  .hello__list {
    margin-bottom: 112px;
    max-width: 629px;
  }
  .hello__el:nth-child(2) {
    margin-bottom: 63px;
  }
  .hello__el:nth-child(2) p {
    font-size: 47px;
  }
  .hello__me {
    width: 35vw;
  }
}
.footer__list {
  display: flex;
}
.footer__el {
  margin-right: 20px;
}
.footer__el:last-child {
  margin-right: 0px;
}

@media (min-width: 1300px) {
  .footer__el img {
    transition: transform 0.2s ease;
  }
  .footer__el img:hover {
    transform: scale(1.1);
  }
}
.projets {
  --border-height: 36px;
  --padding-x: 36px;
  --padding-y: 150px;
  --total-padding-x: calc(var(--padding-x) * 2);
  background-color: #09030D;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url("../assets/images/texture2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: calc(var(--padding-y) - 112px) var(--padding-x) var(--padding-y) var(--padding-x);
  position: relative;
}
.projets::before, .projets::after {
  content: "";
  position: absolute;
  width: calc(100% + var(--padding-x));
  left: calc(-1 * var(--padding-x));
  height: var(--border-height);
  background-image: url("../assets/images/bordure.svg");
  background-repeat: repeat-x;
  animation: scribble infinite 0.4s;
}
.projets::before {
  top: calc(-1 * var(--border-height) / 2);
}
.projets::after {
  bottom: calc(-1 * var(--border-height) / 2);
}
.projets__title {
  padding-top: 112px;
  margin-bottom: 112px;
  position: relative;
  z-index: 9;
}
.projets__list {
  display: grid;
  justify-content: center;
  row-gap: 84px;
}
.projets__el {
  display: none;
  transition: transform 0.3s ease, opacity 0.5s ease;
}
.projets__el:first-child {
  display: block;
  transform: rotate(-10deg);
}
.projets__el:nth-child(3) {
  display: block;
  transform: rotate(2deg);
}
.projets__el:nth-child(5) {
  display: block;
  transform: rotate(10deg);
}
.projets img {
  width: 266px;
  vertical-align: middle;
  animation: smoke 5s ease-in infinite;
}
@keyframes smoke {
  0% {
    filter: drop-shadow(0 0 10px rgba(113, 40, 159, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 30px #71289f);
  }
  100% {
    filter: drop-shadow(0 0 10px rgba(113, 40, 159, 0.5));
  }
}
.projets__follow1 {
  transition: transform 0.1s ease;
}
.projets__follow2 {
  transition: transform 0.3s ease;
}
.projets__follow3 {
  transition: transform 0.2s ease;
}
.projets__presentation {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 20;
  padding: 0 36px;
}
.projets__presentation a {
  margin-top: 15vh;
  margin-bottom: 36px;
}
@media (min-width: 600px) {
  .projets__presentation a {
    width: 354px;
    margin-bottom: 47px;
  }
}
@media (min-width: 1300px) {
  .projets__presentation a {
    width: 472px;
    margin-bottom: 63px;
  }
}
@media (min-width: 2200px) {
  .projets__presentation a {
    width: 629px;
    margin-bottom: 84px;
  }
}
.projets__presentation.hide {
  display: none;
}
.projets__presentation.fade-in {
  opacity: 1;
}
.projets__presentation::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#1b0c21, #09030d);
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.projets__back {
  display: block;
  text-align: center;
  color: #ffffff;
  font-family: "Londrina Solid", sans-serif;
  transition: 0.2s ease;
  font-size: 20px;
  padding: 11px 63px 11px 47px;
  margin: 20px;
  position: relative;
  z-index: 9;
  position: absolute;
  top: 0;
  right: 0;
}
.projets__back::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 36px;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/cross.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 15px;
  z-index: 1;
}
.projets__back::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #71289F;
  z-index: -1;
  animation: scribble infinite 0.4s;
}
@media (min-width: 1300px) {
  .projets__back:hover {
    transform: scale(1.1);
  }
}
.projets__btn.disabled {
  pointer-events: none;
}

@media (min-width: 600px) {
  .projets {
    --border-height: 47px;
    --padding-x: 84px;
    --padding-y: 199px;
  }
  .projets img {
    width: 100%;
  }
  .projets__title {
    margin-bottom: 150px;
  }
  .projets__list {
    grid-template-columns: 1fr 1fr;
    row-gap: 47px;
    column-gap: 47px;
  }
  .projets__el:first-child {
    transform: translateX(50px) scale(0.9) rotate(-5deg);
  }
  .projets__el:nth-child(3) {
    transform: translateX(-50px) scale(0.8);
  }
  .projets__el:nth-child(5) {
    transform: translateY(50px) scale(0.9) rotate(15deg);
  }
  .projets__el:nth-child(2) {
    display: block;
    transform: translateX(50px) translateY(50px) scale(0.7);
  }
  .projets__el:nth-child(7) {
    display: block;
    transform: translateX(50px) translateY(-50px) scale(0.6);
  }
}
@media (min-width: 1300px) {
  .projets {
    --border-height: 63px;
    --padding-x: 12vw;
    --padding-y: 354px;
  }
  .projets__title {
    margin-bottom: 199px;
  }
  .projets__list {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 84px;
    column-gap: 84px;
  }
  .projets__el:first-child {
    transform: translateX(20px) translatey(20px) rotate(10deg) scale(0.9);
  }
  .projets__el:first-child:hover {
    transform: translateX(20px) translatey(20px) rotate(0deg) scale(1);
  }
  .projets__el:nth-child(2) {
    transform: translateX(-50px) translatey(200px) scale(0.7);
  }
  .projets__el:nth-child(2):hover {
    transform: translateX(-50px) translatey(200px) scale(0.8);
  }
  .projets__el:nth-child(3) {
    transform: translateX(-50px) translatey(20px) rotate(-5deg);
  }
  .projets__el:nth-child(3):hover {
    transform: translateX(-50px) translatey(20px) rotate(0deg) scale(1.1);
  }
  .projets__el:nth-child(4) {
    display: block;
    transform: translateX(50px) rotate(-5deg) scale(0.6);
  }
  .projets__el:nth-child(4):hover {
    transform: translateX(50px) rotate(0deg) scale(0.7);
  }
  .projets__el:nth-child(5) {
    transform: translateX(150px) translatey(200px) scale(0.95);
  }
  .projets__el:nth-child(5):hover {
    transform: translateX(150px) translatey(200px) scale(1.05);
  }
  .projets__el:nth-child(6) {
    display: block;
    transform: translateX(50px) translatey(50px) rotate(5deg) scale(0.5);
  }
  .projets__el:nth-child(6):hover {
    transform: translateX(50px) translatey(50px) rotate(0deg) scale(0.6);
  }
  .projets__el:nth-child(7) {
    transform: translateX(250px) translatey(-200px) scale(0.5);
  }
  .projets__el:nth-child(7):hover {
    transform: translateX(250px) translatey(-200px) scale(0.6);
  }
  .projets__link {
    transition: transform 0.3s ease;
  }
  .projets__link:hover {
    transform: scale(1.1);
  }
}
@media (min-width: 2200px) {
  .projets {
    --border-height: 84px;
    --padding-x: 12vw;
    --padding-y: 472px;
  }
  .projets__title {
    margin-bottom: 266px;
  }
}
.jeu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 36px 0 36px;
  position: relative;
}
.jeu__title {
  padding-top: 112px;
}
.jeu::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10vh;
  background: linear-gradient(to top, rgba(113, 40, 159, 0), rgba(113, 40, 159, 0.08));
  z-index: -1;
}
.jeu__list {
  max-width: 354px;
  z-index: 9;
}
.jeu__img1 {
  margin: 84px 0;
}
.jeu__img2 {
  display: none;
}
.jeu__btn {
  display: block;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  color: #ffffff;
  font-family: "Londrina Solid", sans-serif;
  transition: 0.2s ease;
  font-size: 20px;
  padding: 11px 63px 11px 47px;
  position: relative;
}
@media (min-width: 1300px) {
  .jeu__btn:hover {
    transform: scale(1.1);
  }
}
.jeu__btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #71289F;
  z-index: -1;
  animation: scribble infinite 0.4s;
}
.jeu__btn::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 36px;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/play.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 15px;
  z-index: 1;
}
.jeu__container {
  transform: rotate(-10deg);
}
.jeu__follow {
  display: flex;
  justify-content: center;
  transition: transform 0.1s ease;
}

@media (min-width: 600px) {
  .jeu {
    padding: 87px 84px 0 84px;
  }
  .jeu__list {
    max-width: 472px;
  }
  .jeu__btn {
    font-size: 27px;
    padding: 15px 84px 15px 63px;
  }
  .jeu__btn::after {
    content: "";
    right: 47px;
    background-size: 20px;
  }
  .jeu__img1 {
    margin: 112px 0;
  }
}
@media (min-width: 1300px) {
  .jeu {
    padding: 242px 12vw 0 12vw;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .jeu__img1 {
    display: none;
  }
  .jeu__img2 {
    display: block;
  }
  .jeu__btn {
    margin: 0;
    margin-top: 47px;
  }
  .jeu__list {
    margin-bottom: 112px;
  }
}
@media (min-width: 1900px) {
  .jeu__btn {
    margin-top: 63px;
  }
}
@media (min-width: 2200px) {
  .jeu {
    padding: 360px 12vw 0 12vw;
  }
  .jeu__list {
    max-width: 629px;
  }
  .jeu__btn {
    margin-top: 84px;
    font-size: 36px;
    padding: 20px 112px 20px 84px;
  }
  .jeu__btn::after {
    content: "";
    right: 63px;
    background-size: 27px;
  }
}
.main--jeu {
  padding: 87px 36px 36px 36px;
  font-family: "Londrina Solid", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}
.main--jeu h2 {
  margin: 0;
  z-index: 10;
  position: relative;
}
@media (min-width: 600px) {
  .main--jeu {
    padding-top: 99px;
  }
}

.footer--jeu {
  margin-top: 0;
}

.card__list {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
}
@media (min-width: 600px) {
  .card__list {
    padding: 0;
  }
}
.card__pop {
  opacity: 0;
}
.card__pop.visible {
  opacity: 1;
  animation: bounce 0.5s ease forwards;
}
.card__el {
  position: relative;
  padding-top: 21px;
  padding-left: 7px;
  margin: 6px;
  width: 70px;
  height: 87.5px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  transition: transform 0.4s ease-out;
  border-radius: 3px;
  user-select: none;
}
.card__el .front::before,
.card__el .front::after,
.card__el .back::before,
.card__el .back::after {
  top: 21px;
  left: 7px;
  transform: scale(0.5);
}
@media (min-width: 600px) {
  .card__el {
    padding-top: 42px;
    padding-left: 14px;
    width: 140px;
    height: 175px;
    margin: 11px;
  }
  .card__el .front::before,
.card__el .front::after,
.card__el .back::before,
.card__el .back::after {
    top: 42px;
    left: 14px;
    transform: scale(1);
  }
}
.card__el .front,
.card__el .back {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__el .front img,
.card__el .back img {
  width: 70%;
  max-height: 100%;
}
.card__el img {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}
.card__el.matched img {
  opacity: 1;
}
@media (min-width: 1300px) {
  .card__el:hover img {
    opacity: 1;
  }
}

.info {
  width: 100%;
  max-width: 472px;
  z-index: 9;
}
.info__list {
  margin: 36px 0;
  display: flex;
  justify-content: space-evenly;
}
.info__list span {
  color: #7BEA44;
}
.info__list span.is-updating {
  animation: bounce 1s;
}
.info__list span.last-turn {
  color: red;
}
@media (min-width: 600px) {
  .info__list {
    font-size: 27px;
  }
}

.is-selected,
[data-matched=true] {
  transform: translate3d(0, 0, 0) rotateY(180deg);
}

[data-matched=true] {
  animation: matchSuccess 0.4s ease-out 3;
}

.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  text-align: center;
  background: linear-gradient(#1b0c21, #09030d);
  z-index: 20;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  transition: opacity 0.2s ease-out, transform 0s 0.2s;
}
.success.is-visible {
  opacity: 1;
  transform: translate3d(0, 0, 100px);
  transition: opacity 0.2s ease-out, transform 0s;
}
.success .info__list {
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
.success .info__el {
  margin-bottom: 11px;
}
.success .info__el:last-child {
  margin-bottom: 0;
}
.success__icon {
  width: 112px;
  margin: 36px 0 11px 0;
  position: relative;
  transform: scale(1.5);
  background-color: transparent;
  box-shadow: none;
  transform: rotateY(0);
  opacity: 0;
  transition: opacity 0.2s 0.4s;
}
.is-visible .success__icon {
  opacity: 1;
  animation: bounce 0.5s 0.4s ease-out forwards;
}
.success__icon img {
  width: 100%;
}
.success__btn {
  font-family: "Londrina Solid", sans-serif;
  color: #ffffff;
  display: block;
  font-size: 20px;
  padding: 11px 63px 11px 47px;
  margin: 36px 0;
  position: relative;
  transition: transform 0.2s ease;
}
@media (min-width: 1300px) {
  .success__btn:hover {
    transform: scale(1.1);
  }
}
.success__btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #71289F;
  z-index: -1;
  animation: scribble infinite 0.4s;
}
.success__btn::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 36px;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/play.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 15px;
  z-index: 1;
}
@media (min-width: 600px) {
  .success__btn {
    font-size: 27px;
    margin-top: 47px;
    padding: 15px 84px 15px 63px;
  }
  .success__btn::after {
    content: "";
    right: 47px;
    background-size: 20px;
  }
}
@media (min-width: 1900px) {
  .success__btn {
    margin-top: 63px;
  }
}
@media (min-width: 2200px) {
  .success__btn {
    font-size: 36px;
    margin-top: 84px;
    padding: 20px 112px 20px 84px;
  }
  .success__btn::after {
    content: "";
    right: 63px;
    background-size: 27px;
  }
}

.front,
.back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: -6px -6px 36px 2px rgba(0, 0, 0, 0.15) inset;
  backface-visibility: hidden;
  overflow: hidden;
}
.front::before, .front::after,
.back::before,
.back::after {
  content: "";
  position: absolute;
  top: 42px;
  left: 14px;
  width: 7px;
  height: 7px;
}

.front {
  background-color: #1B0C21;
  transform: rotateY(180deg);
}

.back {
  background-color: #71289F;
  transform: rotateY(0);
}

@keyframes matchSuccess {
  0% {
    transform: translate3d(0, 0, 0) rotateY(180deg) scale(1);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateY(180deg) scale(1.05);
  }
  100% {
    transform: translate3d(0, 0, 0) rotateY(180deg) scale(1);
  }
}
@keyframes bounce {
  0% {
    transform: scale(0.1);
  }
  20% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.overlay.invisible {
  display: none;
}
.overlay .overlayDoor::before, .overlay .overlayDoor::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background: #09030D;
  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  transition-delay: 0.8s;
}
.overlay .overlayDoor::before {
  left: 0;
}
.overlay .overlayDoor::after {
  right: 0;
}
.overlay.loaded .overlayDoor::before {
  left: -50%;
}
.overlay.loaded .overlayDoor::after {
  right: -50%;
}
.overlay.loaded .overlayContent {
  opacity: 0;
  margin-top: -15px;
}
.overlay .overlayContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
}
.overlay .overlayContent .skip {
  display: block;
  text-align: center;
  margin: 50px auto 0;
  color: #ffffff;
  font-family: "Londrina Solid", sans-serif;
  transition: 0.2s ease;
  font-size: 20px;
  padding: 11px 63px 11px 47px;
  position: relative;
  z-index: 1;
}
.overlay .overlayContent .skip::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #71289F;
  z-index: -1;
  animation: scribble infinite 0.4s;
}
.overlay .overlayContent .skip::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 36px;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/skip.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 15px;
  z-index: 1;
}
@media (min-width: 1300px) {
  .overlay .overlayContent .skip:hover {
    transform: scale(1.1);
  }
}
@media (min-width: 600px) {
  .overlay .overlayContent .skip {
    font-size: 27px;
    padding: 15px 84px 15px 63px;
  }
  .overlay .overlayContent .skip::after {
    content: "";
    right: 47px;
    background-size: 20px;
  }
}

.loader {
  width: 128px;
  height: 128px;
  border: 3px solid #ffffff;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  position: relative;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader .inner {
  width: 64px;
  height: 64px;
  border: 3px solid transparent;
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  animation: spinInner 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinInner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-720deg);
  }
}
.fall {
  background-color: #71289F;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 50;
}
.fall__title {
  color: #ffffff;
  margin: 0;
}
.fall.hide {
  display: none;
}

.textbox__list {
  background-color: #09030D;
  border-radius: 15px;
  position: relative;
  z-index: 9;
}
.textbox__list::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 6px solid #71289F;
  border-radius: 15px;
  z-index: 1;
  animation: scribble infinite 0.4s;
}
@media (min-width: 600px) {
  .textbox__list::before {
    border-radius: 20px;
  }
}
@media (min-width: 2200px) {
  .textbox__list::before {
    border-radius: 27px;
  }
}
.textbox__list::after {
  content: "";
  background-image: url("../assets/images/indicateur.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  height: fit-content;
  height: 11px;
  width: 20px;
  bottom: 15%;
  right: 10%;
  transform: translateX(50%) translateY(50%);
  z-index: 2;
  animation: continue 1s infinite;
  transition: transform opacity 0.2s ease;
  opacity: 1;
}
@media (min-width: 600px) {
  .textbox__list::after {
    height: 15px;
    width: 27px;
  }
}
@media (min-width: 2200px) {
  .textbox__list::after {
    height: 20px;
    width: 36px;
  }
}
@keyframes continue {
  0% {
    transform: translateX(50%) translateY(50%);
    opacity: 1;
  }
  50% {
    transform: translateX(50%) translateY(60%);
    opacity: 0.5;
  }
  100% {
    transform: translateX(50%) translateY(50%);
    opacity: 1;
  }
}
.textbox__list.hide::after {
  content: none;
}
@media (min-width: 600px) {
  .textbox__list {
    border-radius: 20px;
  }
}
@media (min-width: 2200px) {
  .textbox__list {
    border-radius: 27px;
  }
}
.textbox__el.hide {
  display: none;
}
.textbox__text {
  position: relative;
  font-size: 20px;
  font-family: "Eczar", serif;
  line-height: 140%;
  color: #ffffff;
  padding: 36px 20px;
  text-align: start;
  z-index: 3;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.textbox__text.animation {
  animation: appear 1s forwards;
}
.textbox__text span {
  display: block;
}
@media (min-width: 600px) {
  .textbox__text {
    padding: 47px 27px;
    font-size: 27px;
  }
}
@media (min-width: 2200px) {
  .textbox__text {
    padding: 63px 36px;
    font-size: 36px;
  }
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.textbox__underel {
  display: none;
}
.textbox__underel.visible {
  display: block;
}

.main--credits {
  padding: 87px 36px 36px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .main--credits {
    padding-top: 99px;
  }
}

.credits {
  z-index: 10;
}
.credits h2 {
  padding-top: 36px;
}
.credits h3 {
  font-size: 27px;
  line-height: 120%;
  margin-bottom: 20px;
}
.credits ul {
  margin-bottom: 36px;
}
@media (min-width: 600px) {
  .credits h2 {
    padding-top: 47px;
  }
  .credits h3 {
    font-size: 36px;
    margin-bottom: 27px;
  }
  .credits ul {
    margin-bottom: 47px;
  }
}
@media (min-width: 2200px) {
  .credits h2 {
    padding-top: 63px;
  }
  .credits h3 {
    font-size: 47px;
    margin-bottom: 36px;
  }
  .credits ul {
    margin-bottom: 63px;
  }
}