.menu {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    transform: translateY(0%);
    z-index: 15;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-family: $font-title;
    padding: 20px;
    background: $c-bg;

    &__list {
        display: flex;
        flex-direction: row;
    }

    &__el {
        margin-right: 20px;
        opacity: 0.6;
        transition: opacity 0.2s ease;

        &--active {
            opacity: 1;
        }
    }

    &__el:last-child {
        margin-right: 0;
    }

    &__logo img {
        max-height: 36px;
        width: fit-content;
    }

    &__btn {
        display: none;
    }

    &--hidden {
        transform: translateY(-100%);
    }
}

@media(min-width: 600px) {
    .menu {
        font-size: 27px;
        justify-content: space-between;

        &__el {
            margin-right: 36px;
        }

        &__btn {
            display: block;
            font-size: 20px;
            padding: 11px 63px 11px 47px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $c-btn;
                z-index: -1;
                animation: scribble infinite 0.4s;
            }

            &--play {
                &::after {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    top: 0;
                    right: 36px;
                    width: 100%;
                    height: 100%;
                    background-image: url("../assets/images/play.svg");
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: 15px;
                    z-index: 1;
                }
            }

            &--retry {
                &::after {
                    content: '';
                    position: absolute;
                    pointer-events: none;
                    top: 0;
                    right: 36px;
                    width: 100%;
                    height: 100%;
                    background-image: url("../assets/images/refresh.svg");
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: 20px;
                    z-index: 1;
                }
            }
        }

        &__logo img {
            max-height: 47px;
        }
    }
}

@media(min-width: 1300px) {
    .menu {
        padding: 20px 47px;

        &__container {
            width: 159px;
            display: flex;

            &--game{
                width: 219px;
            }
        }

        &__btn {
            transition: transform 0.2s ease;

            &:hover {
                transform: scale(1.1);
            }
        }

        &__logo {
            transition: transform 0.2s ease;

            &:hover {
                transform: scale(1.1);
            }
        }

        &__el {
            &:hover {
                opacity: 0.8;
            }
        }
    }
}