$p: 7px;
$cardPadding: $p * 2;
$cardWidth: (
  $p*18) + $cardPadding;
$cardHeight: $cardWidth + ($cardPadding*2.5
);

$colors: (
  score: (last: red)
);

@function key($map, $key, $sub-key: null) {
  @if map-has-key($map, $key) {
    $val: map-get($map, $key);

    @if $sub-key and map-has-key($val, $sub-key) {
      $val: map-get($val, $sub-key);
    }

    @return $val;
  }
}

@function color($key, $sub-key: null) {
  @return key($colors, $key, $sub-key);
}

.main--jeu {
  padding: 87px 36px 36px 36px;
  font-family: $font-title;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;

  & h2 {
    margin: 0;
    z-index: 10;
    position: relative;
  }

  @media (min-width: 600px) {
    padding-top: 99px;
  }
}

.footer--jeu {
  margin-top: 0;
}

.card {
  &__list {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1100px;

    @media (min-width: 600px) {
      padding: 0;
    }
  }

  &__pop {
    opacity: 0;

    &.visible {
      opacity: 1;
      animation: bounce 0.5s ease forwards;
    }
  }

  &__el {
    position: relative;
    padding-top: $cardPadding*3/2;
    padding-left: $cardPadding/2;
    margin: 6px;
    width: $cardWidth/2;
    height: $cardHeight/2;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    transition: transform 0.4s ease-out;
    border-radius: 3px;
    user-select: none;

    .front::before,
    .front::after,
    .back::before,
    .back::after {
      top: $cardPadding*3/2;
      left: $cardPadding/2;
      transform: scale(0.5);
    }

    @media (min-width: 600px) {
      padding-top: $cardPadding*3;
      padding-left: $cardPadding;
      width: $cardWidth;
      height: $cardHeight;
      margin: 11px;

      .front::before,
      .front::after,
      .back::before,
      .back::after {
        top: $cardPadding*3;
        left: $cardPadding;
        transform: scale(1);
      }

    }

    .front,
    .back {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 70%;
        max-height: 100%;
      }
    }

    & img {
      opacity: 0.5;
      transition: opacity 0.5s ease;
    }

    &.matched img {
      opacity: 1;
    }

    &:hover {
      & img {
        @media (min-width: 1300px) {
          opacity: 1;
        }
      }
    }
  }
}

.info {
  width: 100%;
  max-width: 472px;
  z-index: 9;

  &__list {
    margin: 36px 0;
    display: flex;
    justify-content: space-evenly;

    span {
      color: $c-title;

      &.is-updating {
        animation: bounce 1s;
      }

      &.last-turn {
        color: color(score, last);
      }
    }

    @media (min-width: 600px) {
      font-size: 27px;
    }
  }
}

.is-selected,
[data-matched=true] {
  transform: translate3d(0, 0, 0) rotateY(180deg);
}

[data-matched=true] {
  animation: matchSuccess 0.4s ease-out 3;
}

// Success!
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  text-align: center;
  background: linear-gradient(rgba($c-bg, 1), rgba($c-bg2, 1));
  z-index: 20;
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  transition:
    opacity 0.2s ease-out,
    transform 0s 0.2s;

  &.is-visible {
    opacity: 1;
    transform: translate3d(0, 0, 100px);
    transition:
      opacity 0.2s ease-out,
      transform 0s;
  }

  & .info {
    &__list {
      margin: 0;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &__el {
      margin-bottom: 11px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    width: 112px;
    margin: 36px 0 11px 0;
    position: relative;
    transform: scale(1.5);
    background-color: transparent;
    box-shadow: none;
    transform: rotateY(0);
    opacity: 0;
    transition: opacity 0.2s 0.4s;

    .is-visible & {
      opacity: 1;
      animation: bounce 0.5s 0.4s ease-out forwards;
    }

    & img {
      width: 100%;
    }
  }

  &__btn {
    font-family: $font-title;
    color: $c-text;
    display: block;
    font-size: 20px;
    padding: 11px 63px 11px 47px;
    margin: 36px 0;
    position: relative;
    transition: transform 0.2s ease;

    &:hover {
      @media (min-width: 1300px) {
        transform: scale(1.1);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $c-btn;
      z-index: -1;
      animation: scribble infinite 0.4s;
    }

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      right: 36px;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/play.svg");
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 15px;
      z-index: 1;
    }

    @media(min-width: 600px) {
      font-size: 27px;
      margin-top: 47px;
      padding: 15px 84px 15px 63px;

      &::after {
        content: '';
        right: 47px;
        background-size: 20px;
      }
    }

    @media(min-width: 1900px) {
      margin-top: 63px;
    }

    @media(min-width: 2200px) {
      font-size: 36px;
      margin-top: 84px;
      padding: 20px 112px 20px 84px;

      &::after {
        content: '';
        right: 63px;
        background-size: 27px;
      }
    }
  }
}

.front,
.back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: -6px -6px 36px 2px rgba(black, 0.15) inset;
  backface-visibility: hidden;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: $cardPadding*3;
    left: $cardPadding;
    width: $p;
    height: $p;
  }
}

.front {
  background-color: $c-bg;
  transform: rotateY(180deg);
}

.back {
  background-color: $c-btn;
  transform: rotateY(0);
}

@keyframes matchSuccess {
  0% {
    transform: translate3d(0, 0, 0) rotateY(180deg) scale(1);
  }

  50% {
    transform: translate3d(0, 0, 0) rotateY(180deg) scale(1.05);
  }

  100% {
    transform: translate3d(0, 0, 0) rotateY(180deg) scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: scale(0.1);
  }

  20% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(0.8);
  }

  60% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}