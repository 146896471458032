.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;

	&.invisible {
		display: none;
	}

	.overlayDoor {

		&::before,
		&::after {
			content: "";
			position: absolute;
			width: 50%;
			height: 100%;
			background: $c-bg2;
			transition: .5s cubic-bezier(.77, 0, .18, 1);
			transition-delay: .8s;
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}
	}

	&.loaded {
		.overlayDoor {
			&::before {
				left: -50%;
			}

			&::after {
				right: -50%;
			}
		}

		.overlayContent {
			opacity: 0;
			margin-top: -15px;
		}
	}

	.overlayContent {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		transition: .5s cubic-bezier(.77, 0, .18, 1);

		.skip {
			display: block;
			text-align: center;
			margin: 50px auto 0;
			color: $c-text;
			font-family: $font-title;
			transition: 0.2s ease;
			font-size: 20px;
			padding: 11px 63px 11px 47px;
			position: relative;
			z-index: 1;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: $c-btn;
				z-index: -1;
				animation: scribble infinite 0.4s;
			}

			&::after {
				content: '';
				position: absolute;
				pointer-events: none;
				top: 0;
				right: 36px;
				width: 100%;
				height: 100%;
				background-image: url("../assets/images/skip.svg");
				background-repeat: no-repeat;
				background-position: center right;
				background-size: 15px;
				z-index: 1;
			}

			&:hover {
				@media(min-width: 1300px) {
					transform: scale(1.1);
				}
			}

			@media (min-width: 600px) {
				font-size: 27px;
				padding: 15px 84px 15px 63px;

            	&::after {
            	    content: '';
            	    right: 47px;
            	    background-size: 20px;
            	}
			}
		}
	}
}

.loader {
	width: 128px;
	height: 128px;
	border: 3px solid $c-text;
	border-bottom: 3px solid transparent;
	border-radius: 50%;
	position: relative;
	animation: spin 1s linear infinite;
	display: flex;
	justify-content: center;
	align-items: center;

	.inner {
		width: 64px;
		height: 64px;
		border: 3px solid transparent;
		border-top: 3px solid $c-text;
		border-radius: 50%;
		animation: spinInner 1s linear infinite;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes spinInner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-720deg);
	}
}