.fall {
    background-color: $c-btn;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    z-index: 50;

    &__title {
        color: $c-text;
        margin: 0;
    }

    &.hide {
        display: none;
    }
}