.footer {
  &__list {
    display: flex;
  }

  &__el {
    margin-right: 20px;
  }

  &__el:last-child {
    margin-right: 0px;
  }
}

@media(min-width: 1300px) {
  .footer {
    &__el img {
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}